module.exports = {
  defaultLocale: "en",

  languages: {
    cms: [
      { locale: "en", title: "English" },
      { locale: "nl", title: "Nederlands" },
      { locale: "es", title: "Español" },
    ],
    website: [
      { locale: "en", title: "English" },
      { locale: "nl", title: "Nederlands" },
      { locale: "es", title: "Español" },
    ],
  },

  templates: {
    pages: [
      { name: "homePage.js", slug: "/" },
      { name: "aboutPage.js", slug: "/about" },
      { name: "partnersPage.js", slug: "/partners" },
      // { name: "blogPage.js", slug: "/blog" },
      // { name: "blogPost.js", slug: "/blog-post" },
    ],
  },
};
